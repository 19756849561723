<template>
    <base-table models-name="lingue"
                model-ref="la lingua"
                new-msg="una nuova lingua"
                :is-loading="isLoading"
                :headers="headers"
                :can-create="$store.getters.isSystem"
                @changeOptions="onChangeOptions($event)"
    >
        <template #header="{ onChangeFilter }">
            <lingue-table-header @changeOptions="onChangeOptions($event)"
                                 @changeFilter="onChangeFilter"
            ></lingue-table-header>
        </template>

        <template #IS_DEFAULT="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on"
                           icon
                           :color="item.IS_DEFAULT ? 'success' : 'error'"
                           :loading="defaultLoading"
                           :disabled="isLoading"
                           @click.stop="onEnableClick(item)"
                    >
                        <v-icon small>fa-{{ item.IS_DEFAULT ? 'check' : 'times' }}</v-icon>
                    </v-btn>
                </template>
                <span>
                    {{ getTooltip(item) }}
                </span>
            </v-tooltip>
        </template>

        <template #dialog>
            <conferma-dialog :dialog="Boolean(dialog)"
                             v-if="Boolean(dialog)"
                             :text="'Impostando ' + dialog.NOME.toUpperCase() + ' come lingua di default ' +
                                    'disattiverai tipologie ed eventi che non possiedono questa lingua nei loro testi'"
                             @close="dialog = false"
                             @confermato="onEnableClick(dialog)"
            ></conferma-dialog>
        </template>

    </base-table>
</template>

<script>
    import BaseTable from '@/components/aaaProject/table/BaseTable';
    import LingueTableHeader from '@/components/secure/lingue/components/LingueTableHeader';
    import ConfermaDialog from '@/components/aaaGenerics/commons/ConfermaDialog';

    export default {
        name: 'LingueTable',
        components: {LingueTableHeader, ConfermaDialog, BaseTable},
        props: {isLoading: Boolean},
        data: () => ({
            dialog: false,
            apiTimer: null,
            defaultLoading: false,
            headers: [
                {
                    text: '#',
                    value: 'ID_REC',
                    align: 'center'
                },
                {
                    text: 'Nome ',
                    value: 'NOME',
                    align: 'center'
                },
                {
                    text: 'Sigla ',
                    value: 'SIGLA',
                    align: 'center'
                },
                {
                    text: 'Default ',
                    value: 'IS_DEFAULT',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Visualizzazioni ',
                    value: 'VISUALIZZAZIONI',
                    align: 'center'
                },
                {
                    value: 'actions',
                    align: 'center',
                    sortable: false
                }
            ]
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            }
        },
        methods: {
            onChangeOptions(options) {
                this.$emit('changeOptions', options);
            },
            getTooltip(item) {
                return item.IS_DEFAULT
                       ? 'La lingua è impostata come lingua di default'
                       : (this.$store.getters.isSystem
                          ? 'Click per impostare ' + item.NOME + ' come lingua di default'
                          : 'La lingua NON è impostata come lingua di default');
            },
            onEnableClick(model) {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //PUT del default della lingua
                        _this.$store.dispatch('defaultRequest', {
                            api: 'PUT_LINGUA_DEFAULT',
                            paylod: model.ID_REC,
                            doResponse: lingua => {
                                _this.$store.state.apiModule.lingue.find(l => l.IS_DEFAULT).IS_DEFAULT = false;
                                model.IS_DEFAULT = lingua.IS_DEFAULT;
                            },
                            doClose: () => {
                                _this.defaultLoading = false;
                                _this.dialog = false;
                            }
                        });
                    }
                }

                if (this.$store.getters.isSystem && !model.IS_DEFAULT) {

                    if (!this.dialog) {
                        return this.dialog = model;
                    }

                    this.defaultLoading = true;
                    this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                    doRequest(this);
                }

            }
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
