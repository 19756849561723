var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"models-name":"lingue","model-ref":"la lingua","new-msg":"una nuova lingua","is-loading":_vm.isLoading,"headers":_vm.headers,"can-create":_vm.$store.getters.isSystem},on:{"changeOptions":function($event){return _vm.onChangeOptions($event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var onChangeFilter = ref.onChangeFilter;
return [_c('lingue-table-header',{on:{"changeOptions":function($event){return _vm.onChangeOptions($event)},"changeFilter":onChangeFilter}})]}},{key:"IS_DEFAULT",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":item.IS_DEFAULT ? 'success' : 'error',"loading":_vm.defaultLoading,"disabled":_vm.isLoading},on:{"click":function($event){$event.stopPropagation();return _vm.onEnableClick(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-"+_vm._s(item.IS_DEFAULT ? 'check' : 'times'))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getTooltip(item))+" ")])])]}},{key:"dialog",fn:function(){return [(Boolean(_vm.dialog))?_c('conferma-dialog',{attrs:{"dialog":Boolean(_vm.dialog),"text":'Impostando ' + _vm.dialog.NOME.toUpperCase() + ' come lingua di default ' +
                                'disattiverai tipologie ed eventi che non possiedono questa lingua nei loro testi'},on:{"close":function($event){_vm.dialog = false},"confermato":function($event){return _vm.onEnableClick(_vm.dialog)}}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }