<template>
    <v-dialog v-model="dialog"
              persistent
              max-width="400px"
              @click:outside.stop="!isLoading && $emit('close')"
              @keydown.esc="!isLoading && $emit('close')"
    >
        <v-card>

            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click.stop="$emit('close')" :loading="isLoading">
                    <v-icon>fa-times</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-subtitle>
                <h-row justify="center">
                    <h-col cols="auto" no-padding>
                        <h2>Conferma</h2>
                    </h-col>
                </h-row>
            </v-card-subtitle>

            <v-divider></v-divider>

            <v-card-text :class="'mt-6 ' + (checkText ? 'pb-0' : '')">
                <h-row>
                    <h-col :class="checkText ? 'pb-0' : ''">
                        <span>{{ text }}</span>
                        <v-switch v-if="checkText" dense inset v-model="checkSelected" :loading="isLoading">
                            <template v-slot:label>
                                <i class="caption">{{ checkText }}</i>
                            </template>
                        </v-switch>
                    </h-col>
                </h-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large color="error" @click.stop="$emit('close')" :loading="isLoading">
                    <span class="ma-2">ANNULLA</span>
                </v-btn>
                <v-btn large color="success" @click.stop="onConferma" :loading="isGlobalLoading || isLoading">
                    <span class="ma-2">CONFERMA</span>
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'ConfermaDialog',
        components: {HRow, HCol},
        props: {dialog: Boolean, text: String, checkText: String},
        data: () => ({
            isLoading: false,
            checkSelected: false
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            }
        },
        methods: {
            onConferma() {
                this.isLoading = true;
                this.$emit('confermato', this.checkSelected);
            }
        }
    }
</script>

<style scoped>

</style>
